import { validateSingle } from './validation';
import { BikeType } from '@/reducer/userData';

export const getValidateBikes = (useDetailedValidation) => (_, userData) => {
  const err = [];
  const bikeErrors = {};

  Object.keys(userData.bikes).map((bikeId) => {
    const bikeUserData: BikeType = userData.bikes[bikeId];
    let bikeErr = [];

    // @ts-ignore
    bikeErr = validateSingle(bikeErr, 'price', !bikeUserData.price);

    if (useDetailedValidation) {
      // @ts-ignore
      bikeErr = validateSingle(bikeErr, 'bikeTypeName', !bikeUserData.bikeTypeName);
      // @ts-ignore
      bikeErr = validateSingle(bikeErr, 'bikeMarke', !bikeUserData.bikeMarke);
    }

    if (bikeErr.length > 0) {
      bikeErrors[bikeId] = bikeErr;
    }
    return null;
  });

  return { errors: err, bikeErrors: bikeErrors };
};
